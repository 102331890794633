<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header d-block">
                <h3 class="card-label text-center font-weight-bolder"><b>{{formData.form_name}}</b></h3>
            </div>
            <div class="card-body">
                <el-form label-width="200px" class="el-form-items">
                    <div class="">
                        <div v-for="(form_item, formindex) in detailFormItem" :key="formindex">
                            <div v-if="form_item && form_item.display_group">
                                <div class="card-sub-title d-flex align-items-center">
                                    <i @click="form_item.display = !form_item.display"
                                       v-if="form_item.display"
                                       class="fas fa-angle-down pr-2"></i>
                                    <i @click=" form_item.display = !form_item.display" v-else
                                       class="fas fa-angle-up pr-2"></i>
                                    <b class="card-label mb-0">{{form_item.group_name}}</b>
                                    <template v-if="formData.copy_group_name">
                                      <el-button class="button-clone ml-5" v-if="form_item.isClone != true" @click="copyGroupName(form_item,formindex)" type="primary">解答欄追加</el-button>
                                      <el-button class="ml-5" v-else @click="removeGroupName(formindex,$event)" type="danger" >x</el-button>
                                    </template>
                                </div>
                                <el-collapse-transition>
                                    <div v-show="form_item.display">
                                        <div v-if="form_item.groups.length > 0" v-for="(group, groupKey)  in form_item.groups">
                                            <div v-if="group.fields.length > 0 && group.display_group" class="group-form " :class="group.child_group_name != 'un_group'?'group-form-middle':'un-group-form'">
                                                <div class="title-group-form" v-if="group.child_group_name != 'un_group'">{{ group.child_group_name }}
                                                    <template v-if="formData.copy_group_name_middle">
                                                      <el-button class="button-clone ml-5" v-if="group.isClone != true" @click="copyGroupNameMiddle(form_item,group,groupKey)" type="primary">解答欄追加</el-button>
                                                      <el-button class="ml-5" v-else @click="removeGroupNameMiddle(form_item.groups,groupKey)" type="danger" >x</el-button>
                                                    </template>
                                                </div>
                                                <div class="" v-for="(item, formKey) in group.fields" :key="formKey">
                                                    <div  v-if="item.input_method ==1 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)" class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-16 mb-6">
                                                                    <div>
                                                                        <el-input
                                                                            class="bg-gray-item" v-model="item.value"></el-input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div v-for="(child, key)  in item.childFields" :key="key">
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 mb-6">
                                                                        <div>
                                                                            <el-input
                                                                                @input="changeValue($event,child)"
                                                                                class="bg-gray-item" v-model="child.value" :type="type" :key="'value_'+key"  ></el-input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div 
                                                         v-if="item.input_method ==2 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-20">
                                                                <div class="el-col el-col-24 el-col-md-5 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)" class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-6">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-19 mb-4">
                                                                    <div>
                                                                        <el-input v-model="item.value" type="textarea" class="bg-gray-item" rows="5"></el-input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2"  v-if="item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-20">
                                                                <div v-for="(child, key)  in item.childFields" :key="key">
                                                                    <div class="el-col el-col-24 el-col-md-5 text-break-all">
                                                                        <div class="text-right mr-6">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-19 mb-7">
                                                                        <div>
                                                                            <el-input
                                                                                @input="changeValue($event,child)"
                                                                                class="bg-gray-item" type="textarea"  :value="child.value"  rows="5" ></el-input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                         v-if="item.input_method ==3 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)"
                                                                               class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-16 mb-7">
                                                                    <div>
                                                                        <el-select class="w--10 bg-gray-item"
                                                                                   v-model="item.value"
                                                                                   placeholder="">
                                                                            <el-option
                                                                                v-for="(valueItem, index) in item.selections.split(',')"
                                                                                :key="index"
                                                                                :label="valueItem"
                                                                                :value="index">
                                                                            </el-option>
                                                                        </el-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields && item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div v-for="(child, key) in item.childFields" :key="key+200" >
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 d-flex justify-content-between mb-7">
                                                                        <div class="w--10">
                                                                            <el-select
                                                                                    style="width: 100%" class="w--10 bg-gray-item"
                                                                                    placeholder=""
                                                                                    v-model="child.value"
                                                                                    @change="$forceUpdate()"
                                                                                    >
                                                                                    <el-option
                                                                                    v-for="(valueItem, index) in item.selections.split(',')"
                                                                                    :key="index"
                                                                                    :label="valueItem"
                                                                                    :value="index">
                                                                                </el-option>
                                                                            </el-select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div 
                                                         v-if="item.input_method ==4 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)"
                                                                               class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-16 d-flex justify-content-between mb-7">
                                                                    <div class="w-100">
                                                                        <el-select
                                                                            v-model="item.value"
                                                                            class="w--10 bg-gray-item"
                                                                            placeholder="" multiple
                                                                            >
                                                                            <el-option
                                                                                v-for="(valueItem, index) in item.selections.split(',')"
                                                                                :key="index"
                                                                                :label="valueItem"
                                                                                :value="index">
                                                                            </el-option>
                                                                        </el-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields && item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div v-for="(child, key) in item.childFields" :key="key+200" >
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 d-flex justify-content-between mb-7">
                                                                        <div class="w--10">
                                                                            <el-select
                                                                                    style="width: 100%" class="w--10 bg-gray-item"
                                                                                    placeholder=""
                                                                                    multiple
                                                                                    v-model="child.value"
                                                                                    @change="$forceUpdate()"
                                                                                    >
                                                                                    <el-option
                                                                                    v-for="(valueItem, index) in item.selections.split(',')"
                                                                                    :key="index"
                                                                                    :label="valueItem"
                                                                                    :value="index">
                                                                                </el-option>
                                                                            </el-select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div
                                                    v-if="item.input_method == 5 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)"
                                                                               class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-8 el-col-md-3">
                                                                    <div class="">
                                                                        <input style="display: none"
                                                                               :id="'file-upload'+item.item_id" type="file"
                                                                               @change="onFileChange($event,item.item_id)">
                                                                        <label :for="'file-upload'+item.item_id"
                                                                               class="file-drag">
                                                                            <div class="start">
                                                                                <div><span
                                                                                    class="file-upload-btn btn btn-primary">ファイル選択</span>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-16 el-col-md-9 show-info-file">
                                                                    <label class="text-gray">{{ ruleForm[item.item_id] }}
                                                                        &nbsp;
                                                                    </label>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-6">
                                                                    <label
                                                                        class="show-history-upload -underline w--10 text-xl-right text-left ml-xl-0 ml-2">
                                                                        ファイル版
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields && item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div v-for="(child, key) in item.childFields" :key="key+200" >
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 mb-7">
                                                                        <div class="el-col el-col-8 el-col-md-3" style="width:fit-content">
                                                                            <div class="">
                                                                                <input style="display: none"
                                                                                    :id="'file-upload'+key" type="file"
                                                                                    @change="uploadfile($event,child)">
                                                                                <label :for="'file-upload'+key"
                                                                                    class="file-drag">
                                                                                    <div class="start">
                                                                                        <div><span
                                                                                            class="file-upload-btn btn btn-primary">ファイル選択</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="el-col el-col-16 el-col-md-19 show-info-file">
                                                                            <label class="text-gray">{{ child.value != '' ? child.value : '' }}
                                                                                &nbsp;
                                                                            </label>
                                                                        </div>
                                                                        <div class="el-col el-col-24 el-col-md-8">
                                                                            <label
                                                                                class="show-history-upload -underline w--10 text-xl-right text-left ml-xl-0 ml-2 pr-0">
                                                                                ファイル版
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row m-0 mb-2"
                                                         v-if="item.input_method ==6 && item.special_processing !=3 && item.display">
                                                        <div class="el-col el-col-24 el-col-md-24">
                                                            <!-- <div class="el-col el-col-24 el-col-md-3 text-break-all">
                                                                <label class="el-form-item__label form-item-ran font-weight-bold"
                                                                       style="">{{ item.label_name }}<lable v-if="showLableRequired(item)" class="text-danger">*</lable></label>
                                                            </div> -->
                                                            <div class="el-col el-col-24 el-col-md-21">
                                                                <label class="el-form-item__label form-item-ran font-weight-bold label-field-preview mb-7"  v-html="item.display_content"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                         v-if="item.input_method ==7 && item.special_processing !=3 && item.display">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)" class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-16 mb-7">
                                                                    <div class="preview-datepicker">
                                                                        <el-date-picker class="bg-gray-item" v-model="item.value" type="datetime" placeholder="">
                                                                        </el-date-picker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields && item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div v-for="(child, key) in item.childFields" :key="key+200" >
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 d-flex justify-content-between mb-7">
                                                                        <el-date-picker class="bg-gray-item w--10" @change="changeDateTime($event,child)" v-model="child.value" type="datetime" placeholder="">
                                                                        </el-date-picker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                         v-if="item.input_method ==8 && item.special_processing !=3">
                                                        <div class="row m-0 mb-2">
                                                            <div class="el-col el-col-24 el-col-md-12">
                                                                <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                    <label
                                                                        class="el-form-item__label form-item-ran font-weight-bold"
                                                                        style="">{{ item.label_name }}
                                                                        <label v-if="showLableRequired(item)" class="text-danger">*
                                                                        </label>
                                                                    </label>
                                                                    <div v-if="item.copy_item > 0" class="text-right mr-2">
                                                                        <el-button @click="addChild(item)" type="primary" >解答欄追加</el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="el-col el-col-24 el-col-md-15">
                                                                    <div v-if="item.url_download_file" style="color:deepskyblue;"  class="preview-datepicker">
                                                                        <a :href="item.url_download_file">{{ showNAmeFile(item) }}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 mb-2" v-if="item.childFields && item.childFields.length > 0">
                                                            <div class="el-col el-col-24 el-col-md-24 mb-7" v-for="(child, key) in item.childFields" :key="key+200">
                                                                <div class="el-col el-col-24 el-col-md-12">
                                                                    <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                        <div class="text-right mr-2">
                                                                            <el-button @click="removeChild(item,key)" type="danger" >x</el-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="el-col el-col-24 el-col-md-16 d-flex justify-content-between mb-7">
                                                                        <div v-if="item.url_download_file" style="color:deepskyblue;"  class="preview-datepicker">
                                                                            <a :href="item.url_download_file">{{ showNAmeFile(item) }}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row m-0 mb-2"
                                                         v-if="item.input_method ==9 && item.special_processing !=3 && item.display">
                                                        <div class="el-col el-col-24 el-col-md-12">
                                                            <div class="el-col el-col-24 el-col-md-8 text-break-all">
                                                                <label
                                                                    class="el-form-item__label form-item-ran font-weight-bold"
                                                                    style="">{{ item.label_name }}
                                                                </label>
                                                            </div>
                                                            <div class="el-col el-col-24 el-col-md-16 mb-7">
                                                              <div>
                                                                <el-input class="bg-gray-item" disabled v-model="item.value"></el-input>
                                                              </div>
                                                            </div>
                                                      </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-collapse-transition>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.w-80 {
    width: 80% !important;
}
.card-custom {
    border-radius: 15px;
}

.card-header {
    border: none;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
    font-size: 1.75rem;
    padding-top: 27px !important;
    padding-bottom: 27px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-custom > .card-header b{
    font-size: 1.75rem;
}

.card-sub-title {
    border-top: 1px solid #EBEDF3;
    border-bottom: 1px solid #EBEDF3;
    padding: 1rem 0.25rem;

}

.transition-box {
    margin-bottom: 10px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
}

.text-initial {
    text-align: initial;
}

.w--10 {
    width: 100%;
}

.text-size-27 {
    font-size: 27px;
}

.file-upload-btn {
    padding: 0.35rem 1rem;
}

.show-history-upload {
    color: #00aff0 !important;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    line-height: 40px;
    padding: 0 12px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.card-sub-title {

}

.el-form-item__label {
    text-align: left !important;
    padding-left: 15px;
    line-height: unset;
}

.text--black-bold {
    color: black;
}

.text-gray {
    color: #606266;
}

.show-info-file {
    padding: 8px 0px 0px 0px;
}

.text-break-all {
    word-break: break-all;
}

.bg-gray-item-textarea {
    background: rgba(9, 30, 66, .48);
}

.group-form-middle {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding : 15px;
    padding-left: 0;
    padding-top: 30px;
    margin: 30px 0;
    border-radius: 5px;
    position: relative;
}

.title-group-form {
    position: absolute;
    top: -13px;
    left: 7px;
    padding: 0px 10px;
    font-size: 15px;
    background: #fff;
}
.el-input{
    padding-left: 20px;
}
.el-select{
    padding-left: 20px;
}
.el-textarea{
      padding-left: 10px;
}
.preview-datepicker{
    padding-left: 20px;
}
.card-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding-left: 23px !important;
    padding-right: 23px !important;
}

.un-group-form{
    padding-top: 15px;
    padding-bottom: 15px;
}

</style>

<script>
import ApiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";
import Popup from "@/view/components/Popup";
import Dialog from "@/view/components/Dialog";
import {GET_BY_PREVIEW} from "../../../../core/services/store/formStore";
import { BUILD_URL_DOWNLOAD_FILE } from "@/core/services/store/file.module";

export default {
    components: {},
    data() {
        return {
            ruleForm: {},
            detailFormItem: [],
            toggleCheck: [],
            formData: {},
            fieldType:constants.FIELDS,
            constants: constants,
        }
    }, computed: {
        queryParam() {
            return this.$route.params
        }
    },
    watch: {
      detailFormItem: {
        handler:function (val, oldVal){
          let objectItem = {};
          let calculationFields = [];
          let listField = [];
          for (let i = 0;i < val.length;i++){
            let groups = val[i].groups;
            for (let j = 0;j < groups.length;j++){
              let fields = groups[j].fields;
              for (let k = 0;k < fields.length;k++){
                listField.push(fields[k]);
                if (fields[k].input_method === this.fieldType.CALCULATION){
                  calculationFields.push(fields[k]);
                }
                if (fields[k].value == parseInt(fields[k].value)){
                  objectItem['item_'+fields[k].item_id] = parseInt(fields[k].value);
                }
                if (fields[k].value == parseFloat(fields[k].value)){
                  objectItem['item_'+fields[k].item_id] = parseFloat(fields[k].value);

                }

              }
            }
          }

          if (calculationFields.length > 0){
            for (let i = 0; i < calculationFields.length; i++){
              let calculation = [];
              let previewCalculation = [];
              for (let j = 0;j < calculationFields[i].calculation_decode.length;j++){
                let currentObject = calculationFields[i].calculation_decode[j];
                switch (currentObject.type){
                  case this.constants.CALCULATION_TYPE.ITEM:
                    // objectItem['item_'+currentObject.id] = 0;
                    calculation.push('objectItem.item_'+currentObject.id);
                    previewCalculation.push(currentObject.label);
                    break;
                  case this.constants.CALCULATION_TYPE.CALCULATION:
                    calculation.push(currentObject.label);
                    previewCalculation.push(currentObject.label);
                    break;
                  case this.constants.CALCULATION_TYPE.VALUE:
                    calculation.push(currentObject.label);
                    previewCalculation.push(currentObject.label);
                    break;
                }
              }
              let calculationString = calculation.join(' ');
              calculationString = calculationString.replace(/x/gi, '*').replace(/:/g, '/');
              let result = ''
              try {
                result = eval(calculationString);
                if (calculationFields[i].rounding_type == this.constants.CALCULATION_ROUND.DOWN){
                  if (parseInt(calculationFields[i].number_decimal) > 0 || calculationFields[i].number_decimal === 0){
                    result = Math.floor(result * Math.pow(10, calculationFields[i].number_decimal)) / Math.pow(10, calculationFields[i].number_decimal);
                  }
                }
                if (calculationFields[i].rounding_type == this.constants.CALCULATION_ROUND.UP){
                  if (parseInt(calculationFields[i].number_decimal) > 0 || calculationFields[i].number_decimal === 0){
                    result = Math.ceil(result * Math.pow(10, calculationFields[i].number_decimal)) / Math.pow(10, calculationFields[i].number_decimal);
                  }
                }
              } catch (error) {
                console.log(error)
              }
              if (isNaN(result)){
                result = '';
              }
              calculationFields[i].value = result;
              calculationFields[i].previewCalculation = previewCalculation.join(' ');
            }
          }


          let that = this;
          let fieldMultipleBranching = listField.filter(function (i){
            if (!i.related_items_decode){
              return false
            }
            if (i.input_method == that.fieldType.SELECTION_MULTIPLE){
              return true;
            }
            return false;
          });

          if (fieldMultipleBranching.length > 0){
            for (let i = 0;i < fieldMultipleBranching.length;i++){
              let field = fieldMultipleBranching[i];
              let compare = false;
              let answerActionCode = Object.values(field.answer_action_decode);
              let choose = Object.values(field.value);
              let options = field.selections.split(',');
              let optionsValues = [];
              for (let i = 0;i < choose.length;i++){
                optionsValues.push(options[choose[i]]);
              }
              let related = Object.values(field.related_items_decode);
              for (let j = 0;j < listField.length;j++){
                  for (let t = 0; t < related.length; t++) {
                      if (related[t].includes(listField[j].item_id)){
                          let duplicate = answerActionCode[t].filter(item => optionsValues.includes(item));
                          if (listField[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
                              if (duplicate.length > 0){
                                  listField[j].display = true;
                              }else{
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }
                          }
                          if (listField[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
                              if (duplicate.length > 0){
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }else{
                                  listField[j].display = true;
                              }
                          }
                      }
                  }
              }
            }
          }


          let fieldBranching = listField.filter(function (i){
            if (!i.related_items_decode){
              return false
            }
            if (i.input_method == that.fieldType.SELECTION){
              return true;
            }
            return false;
          });

          if (fieldBranching.length > 0){
            for (let i = 0;i < fieldBranching.length;i++){
              let field = fieldBranching[i];
              let related = Object.values(field.related_items_decode);
              let options = field.selections.split(',');
              let answerActionCode = Object.values(field.answer_action_decode);
              let textChoose = options[field.value];
                for (let j = 0;j < listField.length;j++){
                    for (let t = 0; t < related.length; t++) {
                        if (related[t].includes(listField[j].item_id)){
                            if (listField[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
                                if (answerActionCode[t].includes(textChoose)){
                                    listField[j].display = true;
                                }else{
                                    listField[j].display = false;
                                    listField[j].value = '';
                                }
                            }
                            if (listField[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
                                if (answerActionCode[t].includes(textChoose)){
                                    listField[j].display = false;
                                    listField[j].value = '';
                                }else{
                                    listField[j].display = true;
                                }
                            }
                        }
                    }
                }
            }
          }

          for (let i = 0;i < val.length;i++){
            let groups = val[i].groups;
            for (let j = 0;j < groups.length;j++){
              let fields = groups[j].fields;
              let fieldShow = fields.filter(function (i){
                return i.display == true;
              })
              if (fieldShow.length > 0){
                groups[j].display_group = true;
              }else{
                groups[j].display_group = false;
              }
            }
          }
          for (let i = 0;i < val.length;i++){
            let groups = val[i].groups;
            let groupShow = groups.filter(function (i){
              return i.display_group == true;
            })
            if (groupShow.length > 0){
              val[i].display_group = true;
            }else{
              val[i].display_group = false;
            }
          }

        },
        deep: true
      }
    },
    created() {
        this.getListFormItemPreview()
    },
    methods: {
        getListFormItemPreview() {
            this.loading = true
            let baseUrl = this.queryParam.form_base_url
            this.$store.dispatch(GET_BY_PREVIEW, {baseUrl}).then(data => {
                this.loading = false
                if (data && data.data) {
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        this.detailFormItem = data.data[0].map(item => {
                            item.display = true
                            return item
                        })
                        // console.log(this.detailFormItem)
                        this.ruleForm = data.data[1]
                        this.toggleCheck = data.data[2]
                        this.formData = data.data[3]

                    } else {
                        this.notifyError(data.message)
                    }
                } else {
                    this.notifyError(this.$t("ERROR_SERVER"))
                }
            }).catch(e => {
                this.loading = false
                this.notifyError(e)
            })
            this.loading = false

        },
        onFileChange(e, index) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.ruleForm[index] = files[0].name;
            // this.createImage(files[0],index);  pendding
        },
        uploadfile(e, it) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
            return;
            it.value = files[0].name
            this.$forceUpdate()
        },
        showLableRequired(data) {
            if (data.item_check_relation) {
                let arrCheck = [];
                data.item_check_relation.map(formItemDetail => {
                    arrCheck.push(formItemDetail.check_method);
                })
                if (arrCheck.includes(1)) {
                    return true
                }
            }
            return false
        },
        showNAmeFile(item){
            if (item.item_file_path) {
                let explode = item.item_file_path.split("/");
                return explode[explode.length - 1]
            }
        },
        async downloadFile($url){
            const buildUrl = await this.$store.dispatch(BUILD_URL_DOWNLOAD_FILE,$url);
            let link = document.createElement('a');
            link.href = buildUrl;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
        },
         copyGroupName($itemGroupName,index){
            let cloneItemGroupName = JSON.parse(JSON.stringify($itemGroupName));
            let block = this.randomString(10);
            cloneItemGroupName.isClone = true;
            cloneItemGroupName.block = block;
            let length = this.detailFormItem.filter((obj) => obj.group_name === cloneItemGroupName.group_name).length;
            cloneItemGroupName.groups = cloneItemGroupName.groups.filter(item => item.isClone !== true);
            var clone = false;
            for (const group of cloneItemGroupName.groups) {
                group.fields = group.fields.filter(function( obj ) {
                    if(obj.special_processing == constants.CHECK_TYPE_INPUT.PASSWORD){
                      return false;
                    }
                    if(obj.special_processing == constants.CHECK_TYPE_INPUT.EMAIL){
                      return false;
                    }
                    if(obj.special_processing == constants.CHECK_TYPE_INPUT.PHONE){
                      return false;
                    }
                    return true;
                });
                for (const field of group.fields) {
                    clone = true
                    let newId = 'group_bg_'+cloneItemGroupName.group_name+'_sm_'+group.child_group_name+'_rd_'+this.randomString(10)+'_block_'+block+'_item_id_'+field.item_id;
                    field.item_id = newId;
                    field.childFields = [];
                    field.value = '';
                    this.ruleForm[newId] = ''
                }
            }
            if (clone){
                this.detailFormItem.splice(length + index , 0, cloneItemGroupName);
            }
        },
        removeGroupName(index,event){
            this.detailFormItem.splice(index, 1);
        },
        copyGroupNameMiddle($itemGroupName,$itemGroupNameMiddle,index){
            let cloneItemGroupName = JSON.parse(JSON.stringify($itemGroupName));
            let cloneItemGroupNameMiddle = JSON.parse(JSON.stringify($itemGroupNameMiddle));
            cloneItemGroupNameMiddle.isClone = true;
            let block = $itemGroupName.block;
            let length = cloneItemGroupName.groups.filter((obj) => obj.child_group_name === cloneItemGroupNameMiddle.child_group_name).length;
            cloneItemGroupNameMiddle.fields = cloneItemGroupNameMiddle.fields.filter(function( obj ) {
                if(obj.special_processing == constants.CHECK_TYPE_INPUT.PASSWORD){
                  return false;
                }
                if(obj.special_processing == constants.CHECK_TYPE_INPUT.EMAIL){
                  return false;
                }
                if(obj.special_processing == constants.CHECK_TYPE_INPUT.PHONE){
                  return false;
                }
                return true;
            });
            for (const field of cloneItemGroupNameMiddle.fields){
                let newId = 'group_bg_'+cloneItemGroupName.group_name+'_sm_'+cloneItemGroupNameMiddle.child_group_name+'_rd_'+this.randomString(10)+'_block_'+block+'_item_id_'+field.origin_item_id;
                field.item_id = newId;
                field.childFields = [];
                field.value = '';
                this.ruleForm[newId] = ''
            }
            if (cloneItemGroupNameMiddle.fields.length > 0){
                $itemGroupName.groups.splice(length + index, 0,cloneItemGroupNameMiddle);
            }
        },
        removeGroupNameMiddle(group,index){
          group.splice(index, 1);
        },
        addChild (item){
            if (
                (item.childFields && item.childFields.length <= 30)
                ||
                item.special_processing != constants.CHECK_TYPE_INPUT.PASSWORD
                &&
                item.special_processing != constants.CHECK_TYPE_INPUT.EMAIL
            ){
                if (item.childFields === undefined){
                  item.childFields = [];
                }
                let itemFieldChild = {};
                // if (item.input_method ==7 ){
                //   itemFieldChild.value = new Date();
                // }else{
                //   itemFieldChild.value = [];
                // }
                itemFieldChild.value = '';

                item.childFields.push(itemFieldChild);
            }
            this.$forceUpdate()
        },
        removeChild (item,$index){
            this.$forceUpdate()
            item.childFields.splice($index,1);
        },
        changeValue($event,item) {
          item.value = $event
          this.$forceUpdate()
        },
      changeDateTime($event,item){
          console.log($event,item);
        item.value = $event;
      }
    }

}
</script>
